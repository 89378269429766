import React, { useState } from "react";

import Block from "./ui/Block";
// import Slider from "./ui/Slider";
import AppartDescriptionBlock from "./AppartDescriptionBlock";

import salon1 from "./images/Appart1/salon.jpg";
import sdb1 from "./images/Appart1/sdb.jpg";
import cuisine1 from "./images/Appart1/cuisine.jpg";
import nuit1 from "./images/Appart1/nuit.jpg";
import plus1 from "./images/Appart1/salon.jpg";
import styled from "styled-components";
import { mediumWidth } from "./ui/Constants";
import cuisine2 from "./images/Studio2/02.png";

import salon2 from "./images/Studio2/07.png";
import sdb2 from "./images/Studio2/17.png";
import nuit2 from "./images/Studio2/13.png";
import Slider from "./Slider";

const SliderImgs = [
    salon1,
    sdb1,
    cuisine1,
    nuit1,
    plus1,
    "/images/Studio2/01.png",
    "/images/Studio2/02.png",
    "/images/Studio2/03.png",
    "/images/Studio2/04.png",
    "/images/Studio2/05.png",
    "/images/Studio2/06.png",
    "/images/Studio2/07.png",
    "/images/Studio2/08.png",
    "/images/Studio2/09.png",
    "/images/Studio2/10.png",
    "/images/Studio2/11.png",
    "/images/Studio2/12.png",
    "/images/Studio2/13.png",
    "/images/Studio2/14.png",
    "/images/Studio2/15.png",
    "/images/Studio2/16.png",
    "/images/Studio2/17.png",
    "/images/Studio2/18.png",
];

const apparts = [
    {
        id: 1,
        title: "Appartement 1",
        description: [
            {
                title: "Salon",
                id: "salon1",
                img: salon1,
                description: ["Canapé", "Fauteuils", "Table basse"],
            },
            {
                title: "Cuisine",
                id: "cuisine1",
                img: cuisine1,
                description: [
                    "Lave vaisselle",
                    "Four micro onde",
                    "Plaque vitro",
                ],
            },
            {
                title: "Nuit",
                id: "nuit1",
                img: nuit1,
                description: ["Grand lit", "TV"],
            },
            {
                title: "Salle de bain",
                id: "sdb1",
                img: sdb1,
                description: ["Baignoire", "Sèche cheveux", "Machine à laver"],
            },
        ],
    },
    {
        id: 2,
        title: "Appartement 2",
        description: [
            {
                title: "Salon",
                id: "salon2",
                img: salon2,
                description: ["Canapé", "Fauteuils", "Table basse"],
            },
            {
                title: "Cuisine",
                id: "cuisine2",
                img: cuisine2,
                description: [
                    "Lave vaisselle",
                    "Four micro onde",
                    "Plaque vitro",
                ],
            },
            {
                title: "Nuit",
                id: "nuit2",
                img: nuit2,
                description: ["Grand lit", "TV"],
            },
            {
                title: "Salle de bain",
                id: "sdb2",
                img: sdb2,
                description: ["Baignoire", "Sèche cheveux", "Machine à laver"],
            },
        ],
    },
];

const StudiosStyled = styled.div`
    margin-bottom: 10vw;

    .StudiosHeader {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        .Block {
            height: 12vmax;
            box-shadow: none;
            cursor: pointer;
        }
    }

    .Block {
        height: auto;
    }

    .Block.Frame {
        display: flex;
        flex-direction: column;
        padding-top: 3vh;
    }

    .Pictures {
        display: grid;
    }

    .Block.Frame.selected {
        box-shadow: -5px 10px 20px #999;
    }

    @media only screen and (max-width: ${mediumWidth}) {
        .StudiosHeader {
            width: 100%;

            .Block {
                margin: 1vw;
            }
        }

        .Pictures {
            width: 100%;
            grid-template-columns: 100%;
        }

        .Block.Frame {
            box-shadow: none;
        }
    }

    @media only screen and (min-width: ${mediumWidth}) {
        .StudiosHeader {
            .Frame {
                margin: 3vw;
            }
        }

        .Pictures {
            width: 100%;
            grid-template-columns: 50% 50%;
        }

        .Block.Frame {
            width: 100%;
        }
    }
`;

const Studios = () => {
    const [currentStudioIndice, setCurrentStudioIndice] = useState(0);
    const [currentStudioTitle, setCurrentStudioTitle] = useState(
        "'La tète dans les étoiles'"
    );

    const renderCurrentStudio = () => {
        if (currentStudioTitle) {
            return (
                <StudioBlock
                    title={currentStudioTitle}
                    indice={currentStudioIndice}
                />
            );
        }
    };

    return (
        <StudiosStyled className="Studios">
            <Slider images={SliderImgs} />

            <Block>
                <span>Présentation des studios</span>
            </Block>

            <div className="StudiosHeader">
                <Block
                    backgroundImage={salon1}
                    className={currentStudioIndice === 0 ? "selected" : ""}
                    onClick={() => {
                        setCurrentStudioIndice(0);
                        setCurrentStudioTitle("'La tète dans les étoiles'");
                    }}
                >
                    <span>
                        Studio
                        <br /> "La tête dans les étoiles"
                    </span>
                </Block>
                <Block
                    backgroundImage={cuisine2}
                    className={currentStudioIndice === 1 ? "selected" : ""}
                    onClick={() => {
                        setCurrentStudioIndice(1);
                        setCurrentStudioTitle("'Art déco'");
                    }}
                >
                    <span>
                        Studio
                        <br /> "Art déco"
                    </span>
                </Block>
            </div>

            {renderCurrentStudio()}
        </StudiosStyled>
    );
};

const StudioBlock = (props: { title: React.ReactNode; indice: number }) => (
    <Block>
        <span className="Title">{props.title}</span>

        <div className="Pictures">
            {apparts[props.indice].description.map((element) => (
                <AppartDescriptionBlock
                    key={`${element.id}_${props.indice}`}
                    title={element.title}
                    img={element.img}
                />
            ))}
        </div>
    </Block>
);

export default Studios;

import React, { useState } from "react";
import styled from "styled-components";

const SliderStyled = styled.div`
    position: relative;
    width: 100%;
    margin-top: 5vh;
    height: 50vh;
    overflow: hidden;
    white-space: nowrap;

    .slider-wrapper {
        position: relative;
        height: 100%;
        width: 100%;
    }

    .slide {
        display: inline-block;
        height: 100%;
        width: 100%;
    }

    .arrow {
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f9f9f9;
        border-radius: 50%;
        cursor: pointer;
        transition: transform ease-in 0.1s;

        position: absolute;
        color: black;
        z-index: 999;
        top: 50%;
    }

    .RightArrow {
        right: 25px;
    }

    .LeftArrow {
        left: 25px;
    }
`;

interface SliderProps {
    images: Array<string>;
}

const Slider = (props: SliderProps) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [translateValue, setTranslateValue] = useState(0);

    const goToPrevSlide = () => {
        if (currentIndex === 0) return;

        setCurrentIndex(currentIndex - 1);
        setTranslateValue(translateValue + slideWidth());
    };

    const goToNextSlide = () => {
        // Exiting the method early if we are at the end of the images array.
        // We also want to reset currentIndex and translateValue, so we return
        // to the first image in the array.
        if (currentIndex === props.images.length - 1) {
            setCurrentIndex(0);
            setTranslateValue(0);
        } else {
            // This will not run if we met the if condition above
            setCurrentIndex(currentIndex + 1);
            setTranslateValue(translateValue - slideWidth());
        }
    };

    const slideWidth = () => {
        const slide = document && document.querySelector(".slide");

        if (slide) {
            return slide.clientWidth;
        } else {
            return 0;
        }
    };

    return (
        <SliderStyled>
            <div
                className="slider-wrapper"
                style={{
                    transform: `translateX(${translateValue}px)`,
                    transition: "transform ease-out 0.45s",
                }}
            >
                {props.images.map((image, i) => (
                    <Slide key={i} image={image} />
                ))}
            </div>

            <LeftArrow onClick={goToPrevSlide} />

            <RightArrow onClick={goToNextSlide} />
        </SliderStyled>
    );
};

interface SlideProps {
    image: string;
}

const Slide = (props: SlideProps) => {
    const styles = {
        backgroundImage: `url(${props.image})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50% 60%",
    };
    return <div className="slide" style={styles} />;
};

interface ArrowProps {
    onClick: () => void;
}

const LeftArrow = (props: ArrowProps) => {
    return (
        <div className="arrow LeftArrow" onClick={props.onClick}>
            {"<"}
        </div>
    );
};

const RightArrow = (props: ArrowProps) => {
    return (
        <div className="arrow RightArrow" onClick={props.onClick}>
            {">"}
        </div>
    );
};

export default Slider;

import React from "react";
import MapLeaflet from "./MapLeaflet";
import "leaflet/dist/leaflet.css";
import styled from "styled-components";
import { color2, mediumWidth } from "./ui/Constants";
import Block from "./ui/Block";

const OuNousTrouverStyled = styled.div`
    .wrapper {
        margin: auto;
        margin-bottom: 3vh;
        display: flex;

        .map-container {
            height: 500px;
            display: flex;

            #map {
                width: 100%;
                height: 100%;
            }

            .leaflet-container {
                width: 100%;
                height: 100%;
                border-radius: 25px;
            }
        }

        .info-container {
            width: 20%;
            height: 80%;
            margin-top: 30px;
            padding: 1em;
            border: 11px double ${color2};
            border-radius: 40px 0px 40px 0px;
        }

        .column {
            align-items: normal;
            display: flex;
            flex-direction: column;
            flex-basis: 100%;
            flex: 1;

            margin-top: 5vh;
            margin-bottom: 5vh;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            margin: 1vw;
        }
    }

    @media only screen and (max-width: ${mediumWidth}) {
        .wrapper {
            flex-direction: column;
        }
    }
`;

const OuNousTrouver = () => (
    <OuNousTrouverStyled>
        <Block>
            <span>Où nous trouver</span>
        </Block>
        <div className="wrapper">
            <div className="column Frame">
                <div className="map-container">
                    <MapLeaflet
                    // infoCallBack={(markerInfo) => {
                    //     this.setState({
                    //         markerInfo,
                    //     });
                    // }}
                    />
                </div>
            </div>
            <div className="column Frame">
                <span>Description</span>
                <div> BLA BLA BLA </div>
            </div>
        </div>

        <div className="wrapper">
            <div className="column Frame">
                <span>Train</span>
                <div> BLA BLA BLA </div>
            </div>
            <div className="column Frame">
                <span>Avion</span>
                <div> BLA BLA BLA </div>
            </div>
            <div className="column Frame">
                <span>Voiture</span>
                <div> BLA BLA BLA </div>
            </div>
        </div>
    </OuNousTrouverStyled>
);

export default OuNousTrouver;

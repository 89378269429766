import React from "react";

import marche from "./images/alentours/marche.jpg";
import palais from "./images/alentours/palais.jpg";
import plages from "./images/alentours/plages.jpg";
import musee from "./images/alentours/musee.jpg";
import shopping from "./images/alentours/shopping.jpg";
import restaurants from "./images/alentours/restaurants.jpg";
import bar from "./images/alentours/bar.jpg";
import styled from "styled-components";
import { mediumWidth } from "./ui/Constants";
import Block from "./ui/Block";

const EvenementsStyled = styled.div`
    .wrapper {
        margin: auto;
        margin-top: 3vh;
        margin-bottom: 3vh;
        display: grid;
        grid-gap: 1vh;
    }

    .flip-card {
        background-color: transparent;
        perspective: 1000px;
    }

    .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }

    .flip-card:hover .flip-card-inner {
        transform: rotateY(180deg);
    }

    .flip-card-front,
    .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .flip-card-front {
        background-color: #bbb;
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-family: "Amatic SC", cursive;
        font-size: 4vw;
        text-shadow: 1px 1px 0 #ffffff, 3px 2px 0 black;
    }

    .flip-card-back {
        color: white;
        background-color: #bbb;
        transform: rotateY(180deg);
    }

    @media only screen and (max-width: ${mediumWidth}) {
        .wrapper {
            height: 300vh;
        }
    }

    // When width > medium-width
    @media only screen and (min-width: ${mediumWidth}) {
        .wrapper {
            height: 100vh;
        }

        .one {
            grid-column-start: 1;
            grid-column-end: 6;
            grid-row: 1;
        }

        .two {
            grid-column-start: 6;
            grid-column-end: 13;
            grid-row: 1;
        }

        .three {
            grid-column-start: 1;
            grid-column-end: 5;
            grid-row: 2;
        }

        .four {
            grid-column-start: 5;
            grid-column-end: 9;
            grid-row: 2;
        }

        .five {
            grid-column-start: 9;
            grid-column-end: 13;
            grid-row: 2;
        }

        .six {
            grid-column-start: 1;
            grid-column-end: 7;
            grid-row: 3;
        }

        .seven {
            grid-column-start: 7;
            grid-column-end: 13;
            grid-row: 3;
        }

        .btn--stripe {
            overflow: hidden;
            position: relative;
        }

        .btn--stripe:after {
            content: "";
            display: block;
            height: 7px;
            width: 100%;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            border-top: 1px solid #666;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
`;

const Evenements = () => {
    return (
        <EvenementsStyled className="evenements">
            <Block>
                <span>Evenements</span>
            </Block>
            <div className="wrapper">
                <div className="one flip-card">
                    <div className="flip-card-inner">
                        <div
                            className="flip-card-front"
                            style={{
                                backgroundImage: `url(${marche})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                            }}
                        >
                            <p> Marché </p>
                        </div>
                        <div className="flip-card-back">
                            <h1>Marché</h1>
                            <p> BLA BLA BLA </p>
                            <p> BLA BLA BLA </p>
                        </div>
                    </div>
                </div>
                <div className="two flip-card">
                    <div className="flip-card-inner">
                        <div
                            className="flip-card-front"
                            style={{
                                backgroundImage: `url(${palais})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                            }}
                        >
                            <p> Palais des festivals </p>
                        </div>
                        <div className="flip-card-back">
                            <p> Palais des festivals </p>
                            <p> BLA BLA BLA </p>
                            <p> BLA BLA BLA </p>
                        </div>
                    </div>
                </div>

                <div className="three flip-card">
                    <div className="flip-card-inner">
                        <div
                            className="flip-card-front"
                            style={{
                                backgroundImage: `url(${plages})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                            }}
                        >
                            <p> Plages </p>
                        </div>
                        <div className="flip-card-back">
                            <p> Plages </p>
                            <p> BLA BLA BLA </p>
                            <p> BLA BLA BLA </p>
                        </div>
                    </div>
                </div>
                <div className="four flip-card">
                    <div className="flip-card-inner">
                        <div
                            className="flip-card-front"
                            style={{
                                backgroundImage: `url(${restaurants})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                            }}
                        >
                            <p> Restaurants </p>
                        </div>
                        <div className="flip-card-back">
                            <p> Restaurants </p>
                            <p> BLA BLA BLA </p>
                            <p> BLA BLA BLA </p>
                        </div>
                    </div>
                </div>
                <div className="five flip-card">
                    <div className="flip-card-inner">
                        <div
                            className="flip-card-front"
                            style={{
                                backgroundImage: `url(${bar})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                            }}
                        >
                            <p> Bars </p>
                        </div>
                        <div className="flip-card-back">
                            <p> Bars </p>
                            <p> BLA BLA BLA </p>
                            <p> BLA BLA BLA </p>
                        </div>
                    </div>
                </div>
                <div className="six flip-card">
                    <div className="flip-card-inner">
                        <div
                            className="flip-card-front"
                            style={{
                                backgroundImage: `url(${musee})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                            }}
                        >
                            <p> Musées </p>
                        </div>
                        <div className="flip-card-back">
                            <p> Musées </p>
                            <p> BLA BLA BLA </p>
                            <p> BLA BLA BLA </p>
                        </div>
                    </div>
                </div>
                <div className="seven flip-card">
                    <div className="flip-card-inner">
                        <div
                            className="flip-card-front"
                            style={{
                                backgroundImage: `url(${shopping})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                            }}
                        >
                            <p> Shopping </p>
                        </div>
                        <div className="flip-card-back">
                            <p> Shopping </p>
                            <p> BLA BLA BLA </p>
                            <p> BLA BLA BLA </p>
                        </div>
                    </div>
                </div>
            </div>
        </EvenementsStyled>
    );
};

export default Evenements;

import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { LogoSvgCut } from "./Logo";
import { color3, mediumWidth, color1, bigWidth } from "./ui/Constants";

const NavbarStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .link-underline {
        overflow: hidden;
        position: relative;
    }

    .link-underline:after {
        content: "";
        display: block;
        height: 7px;
        width: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        border-top: 1px solid #666;
        position: absolute;
        left: 0;
        bottom: 0;
    }

    .NavbarItems {
        display: flex;
        flex-direction: row;

        .MenuItem {
            a {
                font-family: "Amatic SC", cursive;
                box-sizing: border-box;
                color: black;
                cursor: pointer;
                display: block;
                font-style: normal;
                font-weight: 300;
                text-decoration-color: rgb(0, 0, 0);
                text-decoration-line: none;
                text-decoration-style: solid;
            }
        }
    }

    .burgerMenu {
        display: none;
        width: 30px;
        height: 23px;
        transform: rotate(0deg);
        transition: 0.5s ease-in-out;
        cursor: pointer;

        span {
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            background: ${color3};
            border-radius: 9px;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: 0.25s ease-in-out;
        }

        span:nth-child(1) {
            top: 0px;
        }

        span:nth-child(2),
        span:nth-child(3) {
            top: 9px;
        }

        span:nth-child(4) {
            top: 18px;
        }
    }

    .burgerMenu.open {
        span:nth-child(1) {
            top: 9px;
            width: 0%;
            left: 50%;
        }

        span:nth-child(2) {
            transform: rotate(45deg);
        }

        span:nth-child(3) {
            transform: rotate(-45deg);
        }

        span:nth-child(4) {
            top: 9px;
            width: 0%;
            left: 50%;
        }
    }

    @media only screen and (max-width: ${mediumWidth}) {
        @keyframes expand {
            0% {
                height: 0;
            }

            100% {
                height: 100vh;
            }
        }

        .animate {
            animation: expand 0.5s linear;
        }

        background: ${color1};
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 2000;

        .Logo {
            font-size: 8vh;
            font-family: "Fredericka the Great", cursive;
            flex-grow: 1;
            height: 10vh;
        }

        .burgerMenu {
            position: absolute;
            right: 0;
            display: block;
            margin: 3vh 2vh 2vh auto;
        }

        .NavbarItems {
            display: none;
        }

        .NavbarItems.open {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100vh;
        }

        .MenuItem {
            a {
                margin: 1vw;
                font-size: 10vw;
                letter-spacing: 0.9px;
            }
        }
    }

    @media only screen and (min-width: ${mediumWidth}) and (max-width: ${bigWidth}) {
        .MenuItem {
            a {
                margin: 1vw;
                font-size: 3vw;
                letter-spacing: 0.9px;
            }
        }
    }

    @media only screen and (min-width: ${bigWidth}) {
        .MenuItem {
            a {
                margin: 1vw;
                font-size: 2vw;
                letter-spacing: 0.9px;
            }
        }
    }
`;

const Navbar = () => {
    const [burgerOpen, setBurgerOpen] = useState(false);
    let open = burgerOpen ? "open" : "";

    return (
        <NavbarStyled>
            <div
                className="Navbar"
                onClick={() => {
                    setBurgerOpen(!burgerOpen);
                }}
            >
                <LogoSvgCut />
                <div className={"burgerMenu " + open}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className={"NavbarItems " + open}>
                    <Item shouldHighlight={window.location.pathname === "/"}>
                        <Link to="/">Accueil</Link>
                    </Item>
                    <Item
                        shouldHighlight={
                            window.location.pathname === "/Studios"
                        }
                    >
                        <Link to="/Studios">Studios</Link>
                    </Item>
                    <Item
                        shouldHighlight={
                            window.location.pathname === "/Alentours"
                        }
                    >
                        <Link to="/Alentours">Alentours</Link>
                    </Item>
                    <Item shouldHighlight={window.location.pathname === "/FAQ"}>
                        <Link to="/FAQ">Foire aux questions</Link>
                    </Item>
                    <Item
                        shouldHighlight={
                            window.location.pathname === "/OuNoustrouver"
                        }
                    >
                        <Link to="/OuNoustrouver">Où nous trouver</Link>
                    </Item>
                    <Item
                        shouldHighlight={
                            window.location.pathname === "/Galerie"
                        }
                    >
                        <Link to="/Galerie">Galerie</Link>
                    </Item>
                    <Item
                        shouldHighlight={window.location.pathname === "/Blog"}
                    >
                        <Link to="/Blog">Blog</Link>
                    </Item>
                    <Item
                        shouldHighlight={
                            window.location.pathname === "/Evenements"
                        }
                    >
                        <Link to="/Evenements">Evenements</Link>
                    </Item>
                </div>
            </div>
        </NavbarStyled>
    );
};

const Item = (props: {
    shouldHighlight: boolean;
    children: React.ReactNode;
}) => (
    <div
        className={
            props.shouldHighlight ? "MenuItem link-underline " : "MenuItem"
        }
    >
        {props.children}
    </div>
);

export default Navbar;

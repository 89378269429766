export const smallWidth = "400px";
export const mediumWidth = "800px";
export const bigWidth = "1200px";
export const maxWidth = "1400px";

export const color1 = "rgba(255, 255, 255, 1);";
export const color2 = "rgba(251, 244, 225, 1)";
export const color3 = "rgba(142, 142, 142, 1)";
export const color3_1 = "#8e8e8e47";
export const color4 = "rgba(100, 100, 100, 1)";
export const color5 = "rgba(183, 165, 122, 1)";

import React from "react";
import blogBackground from "./images/blogBackground.jpg";
import styled from "styled-components";
import Block from "./ui/Block";

const articlesDB = [
    {
        date: "12/12/12",
        title: "Kartoffelnsalad",
        picture: blogBackground,
        text:
            "Free concerts in Venice while staying in a hostel? Anda has you covered with our “Anda Beat – Fall Edition”!! We have teamed up with some local djs and musicians who will be performing live at Anda Venice. Make sure you check out our calendar of concerts and dj set and if you are looking for a hostel in Venice with music events and social atmosphere, well, you have now the answer! Oh, didn’t we say that all our events…",
    },
    {
        date: "13/12/12",
        title: "Kartoffelnsalad",
        picture: blogBackground,
        text:
            "Free concerts in Venice while staying in a hostel? Anda has you covered with our “Anda Beat – Fall Edition”!! We have teamed up with some local djs and musicians who will be performing live at Anda Venice. Make sure you check out our calendar of concerts and dj set and if you are looking for a hostel in Venice with music events and social atmosphere, well, you have now the answer! Oh, didn’t we say that all our events…",
    },
    {
        date: "14/12/12",
        title: "Kartoffelnsalad",
        picture: blogBackground,
        text:
            "Free concerts in Venice while staying in a hostel? Anda has you covered with our “Anda Beat – Fall Edition”!! We have teamed up with some local djs and musicians who will be performing live at Anda Venice. Make sure you check out our calendar of concerts and dj set and if you are looking for a hostel in Venice with music events and social atmosphere, well, you have now the answer! Oh, didn’t we say that all our events…",
    },
    {
        date: "15/12/12",
        title: "Kartoffelnsalad",
        picture: blogBackground,
        text:
            "Free concerts in Venice while staying in a hostel? Anda has you covered with our “Anda Beat – Fall Edition”!! We have teamed up with some local djs and musicians who will be performing live at Anda Venice. Make sure you check out our calendar of concerts and dj set and if you are looking for a hostel in Venice with music events and social atmosphere, well, you have now the answer! Oh, didn’t we say that all our events…",
    },
];

const BlogStyled = styled.div`
    .wrapper {
        margin: auto;
        margin-bottom: 3vh;
        display: flex;
        flex-direction: column;
    }
`;

const Blog = () => (
    <BlogStyled className="blog">
        <Block>
            <span>Blog</span>
        </Block>
        <div className="wrapper">
            {articlesDB.map((article) => (
                <Article
                    key={article.date + article.title}
                    article={{
                        ...article,
                    }}
                />
            ))}
        </div>
    </BlogStyled>
);

const ArticleStyled = styled.div`
    display: flex;
    flex-direction: column;

    .date {
    }

    .title {
    }

    .picture {
        img {
            width: 100%;
        }
    }

    .text {
    }

    .separator {
        border-radius: 1px;
        border-bottom: solid;
        margin-bottom: 3vh;
        margin-top: 3vh;
    }
`;

const Article = (props: {
    article: {
        date: string;
        title: string;
        picture: string;
        text: string;
    };
}) => {
    const { article } = props;

    if (article) {
        const { date, title, picture, text } = article;

        return (
            <ArticleStyled>
                <span className="date">{date}</span>
                <span className="title">{title}</span>
                <div className="picture">
                    <img src={picture} alt="" />
                </div>
                <div className="text">{text}</div>

                <div className="separator" />
            </ArticleStyled>
        );
    }
    return <div />;
};

export default Blog;

import React from "react";
import styled from "styled-components";
import Block from "./ui/Block";
import { mediumWidth, bigWidth } from "./ui/Constants";

const questions = [
    {
        title: "General infos",
        questions: [
            {
                question: "What are the main hostel timing?",
                answer:
                    "We operate a 24/24 reception service, and accept late check-in up to 3am. Our check-in time is 2pm, our check-out time is from 6am to 10am.",
            },
            {
                question: "What’s included in your rates?",
                answer:
                    "Our rates always include linen, VAT and WiFi. The local city tax is not included in the rates.",
            },
            {
                question: "How do I get to your hostel?",
                answer:
                    "Visit our Location page for full directions from the main transport terminals.",
            },
        ],
    },
    {
        title: "Accomodation",
        questions: [
            {
                question: "Can I stay in the same dorm as my friends?",
                answer:
                    "While we always try to have friends stay in the same dorms and veeeery rarely split them, we cannot guarantee it when booking individual beds. Do you want to make sure you and your friends will be allocated to the same dorm? Book and entire dorm for private and exclusive use! Easy peasy!",
            },
            {
                question:
                    "Can I request to be accommodated on a specific floor/bunk?",
                answer:
                    "You can of course request it and we will do our best to make you happy However, we cannot guarantee it as it depends on the availability of the day: please remind our staff upon arrival.",
            },
            {
                question: "Do you require a key card security deposit?",
                answer: "No, we do not.",
            },
        ],
    },
    {
        title: "Luggage",
        questions: [
            {
                question: "Can I store my luggage prior to check-in?",
                answer:
                    "If you arrive before 2pm you can leave your bag in our luggage storage in the welcome area. It’s free of charge. As much as we like to store everybody’s luggage please bear in mind that the luggage storage might not always be available during the peak season and is available on a 1st come, 1st served basis.",
            },
            {
                question: "Can I store my luggage after check-out?",
                answer:
                    "Yes of course, it's free but always keep in mind that in peak season it is available on a 1st come, 1st served basis!",
            },
            {
                question: "Do you provide luggage cage in the dorms?3",
                answer:
                    "Every single bed is provided with a luggage cage under the beds. It’s 70X90X30cm (make sure you bring your own padlock).",
            },
        ],
    },
    {
        title: "Services",
        questions: [
            {
                question: "Is there any laundry service?",
                answer:
                    "We have a self-service launderette room; it is open 24/24 for all our guests. It works with coins (change is not given, make sure you have some spare change 😊) We have laundry machines (it’s 3€ per load, 1 load is roughly 3 kilos, washing powder is included in the price and loaded automatically in the washing machine) and dryers with different temperatures (it’s 3€ per load, 1 load is roughly 3 kilos).",
            },
            {
                question: "Is there any guest kitchen available?",
                answer:
                    "Our guest kitchen is free to use and open from 07am to 10pm. It’s fully equipped with cookers, microwave and washdisher. It’s provided with kitchen utensils and anything needed to cook. 2 fridges are also available.",
            },
            {
                question: "Do you provide breakfast?",
                answer:
                    "Breakfast is served every day from 7.30am to 10.30am. It is not included in the rates and it can pre-booked online with your accommodation or upon check-in.",
            },
        ],
    },
];

const FAQStyled = styled.div`
    .category {
        width: 100%;
        margin-top: 5vh;
        display: flex;
        flex-direction: column;

        .title {
            color: #8e8e8e;
            letter-spacing: 0.9px;
            font-size: 4vh;
            margin: auto;
            margin-bottom: 2vh;
        }

        .questions {
            display: flex;
            flex-direction: row;
            justify-content: center;

            .one-question {
                margin: 1vw;
                flex-grow: 1;
                box-shadow: -5px 10px 20px #999;
                border-width: 10px;
                justify-content: center;
                align-items: center;

                .question-title {
                    color: #8e8e8e;
                    font-size: 20px;
                    letter-spacing: 0.9px;
                    margin-top: 0;
                }

                .answer {
                }
            }
        }
    }

    @media only screen and (max-width: ${mediumWidth}) {
        .category {
            .questions {
                flex-direction: column;

                .one-question {
                    margin: 5vw;

                    padding: 3vw;

                    .question-title {
                    }

                    .answer {
                    }
                }
            }
        }
    }

    @media only screen and (min-width: ${mediumWidth}) and (max-width: ${bigWidth}) {
        .category {
            .questions {
                .one-question {
                    margin: 2vw;
                    padding: 1vw;

                    .question-title {
                    }

                    .answer {
                    }
                }
            }
        }
    }

    @media only screen and (min-width: ${bigWidth}) {
        .category {
            .questions {
                .one-question {
                    margin: 2vw;
                    padding: 1vw;

                    .question-title {
                    }

                    .answer {
                    }
                }
            }
        }
    }
`;

const FAQ = () => (
    <FAQStyled className="faq">
        <Block>
            <span>Foire aux questions</span>
        </Block>
        {questions.map((question) => (
            <Question
                key={question.title}
                title={question.title}
                questions={question.questions}
            />
        ))}
    </FAQStyled>
);

interface QuestionProps {
    title: string;
    questions: Array<{
        question: string;
        answer: string;
    }>;
}

const Question = (props: QuestionProps) => {
    const randomIntFromInterval = (min: number, max: number) => {
        // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min);
    };

    const renderScotch = () => {
        const random = randomIntFromInterval(0, 1);
        if (random) {
            return (
                <div>
                    <div className="scotch scotch-small-left"></div>
                    <div className="scotch scotch-small-right"></div>
                </div>
            );
        }
        return (
            <div>
                <div className="scotch scotch-big-middle-top"></div>
            </div>
        );
    };

    const getRandomRotation = () => {
        const random = randomIntFromInterval(0, 0);
        return `rotate(${random}deg)`;
    };

    return (
        <div className="category">
            <span className="title">{props.title}</span>
            <div className="questions">
                {props.questions.map((question) => (
                    <div
                        className="one-question"
                        style={{
                            transform: getRandomRotation(),
                        }}
                        key={question.question}
                    >
                        {renderScotch()}
                        <p className="question-title">{question.question}</p>
                        <p className="answer">{question.answer}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQ;

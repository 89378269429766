import React, { useState } from "react";
import styled from "styled-components";
import { color4, mediumWidth, bigWidth } from "./ui/Constants";

const AppartDescriptionBlockStyled = styled.div`
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 7%;
    transform: rotate(-1deg);
    display: inline-grid;

    .photo-container {
        overflow: hidden;
        width: 90%;
        margin: 5%;
    }

    // .polaroid:nth-child(2n) {
    //     transform: rotate(1deg);
    // }

    .descriptionList {
        background-color: white;
        color: black;
        box-shadow: 10px 20px 22px -17px rgba(0, 0, 0, 0.2);
        border-top-left-radius: 255px 15px;
        border-top-right-radius: 16px 225px;
        border-bottom-right-radius: 225px 15px;
        border-bottom-left-radius: 15px 255px;
        border: solid 2px #41403e;
    }

    .row1 {
        display: flex;
        flex-direction: row;
        margin: 20px;
    }

    .column1 {
        font-size: 2vh;
        margin: 1vh;
    }

    .toto {
        background-size: cover;
        background-position: center;
        min-height: 150px;
        margin: 0.5em;
    }

    .centerBlock {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2em;
        color: white;
    }

    img.cardimg {
        width: 85%;
        padding: 30px;
        padding-bottom: 0;
        vertical-align: middle;
    }

    img.cardimg {
        transition: all 0.5s ease;
        visibility: hidden;
    }

    img.cardimg:hover {
        -webkit-transform: scale(1.02);
        transform: scale(1.02);
    }

    .postit {
        line-height: 1;
        text-align: center;
        width: 275px;
        margin: 25px;
        min-height: 250px;
        max-height: 250px;
        padding-top: 35px;
        position: relative;
        border: 1px solid #e8e8e8;
        border-top: 60px solid #fdfd86;
        font-size: 22px;
        border-bottom-right-radius: 60px 5px;
        display: inline-block;
        background: #ffff88;
        /* Old browsers */
        background: -moz-linear-gradient(
            -45deg,
            #ffff88 81%,
            #ffff88 82%,
            #ffff88 82%,
            #ffffc6 100%
        );
        /* FF3.6+ */
        background: -webkit-gradient(
            linear,
            left top,
            right bottom,
            color-stop(81%, #ffff88),
            color-stop(82%, #ffff88),
            color-stop(82%, #ffff88),
            color-stop(100%, #ffffc6)
        );
        /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(
            -45deg,
            #ffff88 81%,
            #ffff88 82%,
            #ffff88 82%,
            #ffffc6 100%
        );
        /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(
            -45deg,
            #ffff88 81%,
            #ffff88 82%,
            #ffff88 82%,
            #ffffc6 100%
        );
        /* Opera 11.10+ */
        background: -ms-linear-gradient(
            -45deg,
            #ffff88 81%,
            #ffff88 82%,
            #ffff88 82%,
            #ffffc6 100%
        );
        /* IE10+ */
        background: linear-gradient(
            135deg,
            #ffff88 81%,
            #ffff88 82%,
            #ffff88 82%,
            #ffffc6 100%
        );
        /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffff88', endColorstr='#ffffc6', GradientType=1);
        /* IE6-9 fallback on horizontal gradient */
    }

    .postit:after {
        content: "";
        position: absolute;
        z-index: -1;
        right: -0px;
        bottom: 20px;
        width: 200px;
        height: 25px;
        background: rgba(0, 0, 0, 0.2);
        box-shadow: 2px 15px 5px rgba(0, 0, 0, 0.4);
        -moz-transform: matrix(-1, -0.1, 0, 1, 0, 0);
        -webkit-transform: matrix(-1, -0.1, 0, 1, 0, 0);
        -o-transform: matrix(-1, -0.1, 0, 1, 0, 0);
        -ms-transform: matrix(-1, -0.1, 0, 1, 0, 0);
        transform: matrix(-1, -0.1, 0, 1, 0, 0);
    }

    div.container {
        text-align: center;
        padding: 10px 20px;

        p.title {
            text-shadow: 0px 1px 0 #fbfae8, -1px 1px 0 white;
            color: ${color4};
            position: absolute;
            top: 0;
            left: 68px;
            z-index: 10;
        }
    }

    @media only screen and (max-width: ${mediumWidth}) {
        .photo-container {
            height: 300px;
        }

        div.container {
            p.title {
                font-size: 8vw;
            }
        }
    }

    @media only screen and (min-width: ${mediumWidth}) and (max-width: ${bigWidth}) {
        .photo-container {
            height: 400px;
        }

        div.container {
            p.title {
                font-size: 3vw;
            }
        }
    }

    @media only screen and (min-width: ${bigWidth}) {
        .photo-container {
            height: 400px;
        }

        div.container {
            p.title {
                font-size: 3vw;
            }
        }
    }
`;

const AppartDescriptionBlock = (props: { img: any; title: string }) => {
    const [zoom, setZoom] = useState(1);

    const randomIntFromInterval = (min: number, max: number) => {
        // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min);
    };

    const renderScotch = () => {
        const random = randomIntFromInterval(0, 1);
        if (random) {
            return (
                <div>
                    <div className="scotch scotch-small-left"></div>
                    <div className="scotch scotch-small-right"></div>
                </div>
            );
        }
        return (
            <div>
                <div className="scotch scotch-big-middle-top"></div>
            </div>
        );
    };

    return (
        <AppartDescriptionBlockStyled>
            <div className="photo-container">
                <div
                    style={{
                        backgroundImage: `url(${props.img})`,
                        width: "100%",
                        height: "100%",
                        backgroundSize: "cover",
                        transform: `scale(${zoom})`,
                        transition: "all .5s ease-in-out",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: `$0px 0px`,
                    }}
                    onMouseEnter={(e) => {
                        setZoom(1.1);
                    }}
                    onMouseLeave={(e) => {
                        setZoom(1);
                    }}
                    onMouseMove={(e) => {
                        /*
                        console.log({ x: e.screenX, y: e.screenY }) */
                    }}
                ></div>
            </div>
            <div className="container">
                {renderScotch()}
                <div className="column1 ">{props.title}</div>
            </div>
        </AppartDescriptionBlockStyled>
    );
};

export default AppartDescriptionBlock;

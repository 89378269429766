import React from "react";
import styled from "styled-components";

const BlockStyled = styled.div`
    background-color: rgba(233, 171, 88, 0.5);
    border-radius: 25px;
    box-shadow: -5px 10px 20px #999;
    border-width: 10px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    background-color: white;

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: "Amatic SC", cursive;
    text-transform: uppercase;
    font-size: 3vmin;
    letter-spacing: 0.6vw;

    span {
        text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
        text-align: center;
    }
`;

interface BlockProps {
    backgroundImage?: any;
    children?: any;
    className?: string;
    style?: any;
    onClick?: () => void;
}

const Block = (props: BlockProps) => {
    return (
        <BlockStyled
            className={`Block Frame ${props.className}`}
            style={{
                ...props.style,
                backgroundImage: `url('${props.backgroundImage}')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}
            onClick={props.onClick}
        >
            {props.children ? props.children : ""}
        </BlockStyled>
    );
};

export default Block;

import React, { MutableRefObject, useRef, useState } from "react";
import { Map, Marker, TileLayer } from "react-leaflet";
import Leaflet, { Icon, LeafletEvent, PointExpression } from "leaflet";

import plane from "./images/MapIcons/plane.svg";
import train from "./images/MapIcons/train.svg";
import beach from "./images/MapIcons/beach.svg";
import palais from "./images/MapIcons/palais.svg";
import home from "./images/MapIcons/home.svg";
import styled from "styled-components";

const MapLeafletStyled = styled.div``;

interface MapLeafletProps {}

interface MapCustom extends Map, MutableRefObject<MapCustom> {
    trainStationMarker?: Marker;
    airportMarker?: Marker;
    beachMarker?: Marker;
    palaisMarker?: Marker;
    appartAmauryMarker?: Marker;
    appartCamilleMarker?: Marker;
    map?: Map;
}

const MapLeaflet = (props: MapLeafletProps) => {
    const mapRef = useRef<MapCustom>({} as MapCustom);

    const mapCenter = {
        lat: 43.554,
        lng: 7.022348,
    };

    const defaultIconSize = 30;
    const zoom = 15;
    const maxZoom = 17;
    const minZoom = 12;

    const airportMarker = {
        lat: 43.659808,
        lng: 7.214778,
    };

    const [airportMarkerIcon, setAirportMarkerIcon] = useState(
        Leaflet.icon({
            iconUrl: plane,
        })
    );

    const [trainStationMarkerIcon, setTrainStationMarkerIcon] = useState<Icon>(
        Leaflet.icon({
            iconUrl: train,
        })
    );

    const [beachMarkerIcon, setBeachMarkerIcon] = useState(
        Leaflet.icon({
            iconUrl: beach,
        })
    );

    const [palaisMarkerIcon, setPalaisMarkerIcon] = useState(
        Leaflet.icon({
            iconUrl: palais,
        })
    );

    const [appartAmauryMarkerIcon, setAppartAmauryMarkerIcon] = useState(
        Leaflet.icon({
            iconUrl: home,
        })
    );

    const [appartCamilleMarkerIcon, setAppartCamilleMarkerIcon] = useState(
        Leaflet.icon({
            iconUrl: home,
        })
    );

    const trainStationMarker = {
        lat: 43.553953,
        lng: 7.019557,
    };

    const beachMarker = {
        lat: 43.549881,
        lng: 7.022492,
    };

    const palaisMarker = {
        lat: 43.550851,
        lng: 7.017453,
    };
    const appartAmauryMarker = {
        lat: 43.559462,
        lng: 7.020815,
    };
    const appartCamilleMarker = {
        lat: 43.557816,
        lng: 7.022148,
    };

    const [goToAirportMarker, setGoToAirportMarker] = useState({
        lat: 0,
        lng: 0,
    });

    const [goToTrainStationMarker, setGoToTrainStationMarker] = useState({
        lat: 0,
        lng: 0,
    });
    const [goToBeachMarker, setGoToBeachMarker] = useState({
        lat: 0,
        lng: 0,
    });
    const [goToPalaisMarker, setGoToPalaisMarker] = useState({
        lat: 0,
        lng: 0,
    });
    const [goToAppartAmauryMarker, setGoToAppartAmauryMarker] = useState({
        lat: 0,
        lng: 0,
    });

    const [goToAppartCamilleMarker, setGoToAppartCamilleMarker] = useState({
        lat: 0,
        lng: 0,
    });

    const computeFollowingMarkerLatLng = (
        map: Map,
        latLng: { lat: number; lng: number }
    ) => {
        let center = map.leafletElement.getCenter();
        let mapBounds = map.leafletElement.getBounds();
        const northWest = mapBounds.getNorthWest();
        const northEast = mapBounds.getNorthEast();
        const southEast = mapBounds.getSouthEast();
        const southWest = mapBounds.getSouthWest();
        const markerLat = latLng.lat;
        const markerLng = latLng.lng;
        const centerLat = center.lat;
        const centerLng = center.lng;

        if (!mapBounds.contains(latLng)) {
            if (markerLat >= centerLat) {
                // North
                if (markerLng >= centerLng) {
                    // North East
                    if (markerLat > northWest.lat) {
                        // 1
                        return {
                            lat: northEast.lat,
                            lng:
                                markerLng > northEast.lng
                                    ? northEast.lng
                                    : markerLng,
                        };
                    } else {
                        // 2
                        return { lat: markerLat, lng: northEast.lng };
                    }
                } else {
                    // North West
                    if (markerLat > northWest.lat) {
                        // 8
                        return {
                            lat: northWest.lat,
                            lng:
                                markerLng < northWest.lng
                                    ? northWest.lng
                                    : markerLng,
                        };
                    } else {
                        // 7
                        return { lat: markerLat, lng: northWest.lng };
                    }
                }
            } else {
                // South
                if (markerLng >= centerLng) {
                    // South East
                    if (markerLat > southEast.lat) {
                        // 3
                        return { lat: markerLat, lng: southEast.lng };
                    } else {
                        // 4
                        return {
                            lat: southEast.lat,
                            lng:
                                markerLng > southEast.lng
                                    ? southEast.lng
                                    : markerLng,
                        };
                    }
                } else {
                    // South West
                    if (markerLat > southWest.lat) {
                        // 6
                        return { lat: markerLat, lng: southWest.lng };
                    } else {
                        return {
                            lat: southWest.lat,
                            lng:
                                markerLng < southWest.lng
                                    ? southWest.lng
                                    : markerLng,
                        };
                    }
                }
            }
        } else {
            return { lat: 0, lng: 0 };
        }
    };

    const computeMarkers = () => {
        let map = mapRef.current;

        if (map) {
            setGoToAirportMarker(
                computeFollowingMarkerLatLng(map, airportMarker)
            );

            setGoToTrainStationMarker(
                computeFollowingMarkerLatLng(map, trainStationMarker)
            );
            setGoToBeachMarker(computeFollowingMarkerLatLng(map, beachMarker));
            setGoToPalaisMarker(
                computeFollowingMarkerLatLng(map, palaisMarker)
            );
            setGoToAppartAmauryMarker(
                computeFollowingMarkerLatLng(map, appartAmauryMarker)
            );
            setGoToAppartCamilleMarker(
                computeFollowingMarkerLatLng(map, appartCamilleMarker)
            );
        }
    };

    // const callInfoCallBack = (data) => {
    //     props.infoCallBack(data);
    // };

    const onClickMarker = (option: any) => {
        mapRef.current &&
            mapRef.current.leafletElement.setView(option.position, 17);
        // callInfoCallBack(marker.props.data.key);
    };

    const scaleIcons = () => {
        if (mapRef.current) {
            const zoom = mapRef.current.leafletElement.getZoom();
            const iconSize = (defaultIconSize / maxZoom) * zoom;
            const iconAnchor = ((defaultIconSize / maxZoom) * zoom) / 2;
            const sizeData = {
                iconSize: { x: iconSize, y: iconSize } as PointExpression,
                iconAnchor: { x: iconAnchor, y: iconAnchor } as PointExpression,
            };

            setTrainStationMarkerIcon(
                Leaflet.icon({
                    iconUrl: train,
                    ...sizeData,
                })
            );
            setAirportMarkerIcon(
                Leaflet.icon({
                    iconUrl: plane,
                    ...sizeData,
                })
            );
            setBeachMarkerIcon(
                Leaflet.icon({
                    iconUrl: beach,
                    ...sizeData,
                })
            );
            setPalaisMarkerIcon(
                Leaflet.icon({
                    iconUrl: palais,
                    ...sizeData,
                })
            );
            setAppartAmauryMarkerIcon(
                Leaflet.icon({
                    iconUrl: home,
                    ...sizeData,
                })
            );
            setAppartCamilleMarkerIcon(
                Leaflet.icon({
                    iconUrl: home,
                    ...sizeData,
                })
            );
        }
    };

    const refreshMap = () => {
        computeMarkers();
        scaleIcons();
    };

    const onMove = (e: LeafletEvent) => {
        refreshMap();
    };

    const onWhenReady = () => {
        refreshMap();
    };

    return (
        <MapLeafletStyled id="map">
            <Map
                ref={mapRef}
                center={mapCenter}
                zoom={zoom}
                maxZoom={maxZoom}
                minZoom={minZoom}
                onmove={onMove}
                whenReady={onWhenReady}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker
                    data={{ key: "airport" }}
                    position={airportMarker}
                    icon={airportMarkerIcon}
                    onClick={(e: any) => {
                        onClickMarker(e.target.options);
                    }}
                />
                <Marker
                    data={{ key: "trainStation" }}
                    position={trainStationMarker}
                    icon={trainStationMarkerIcon}
                    onClick={(e: any) => {
                        onClickMarker(e.target.options);
                    }}
                />
                <Marker
                    data={{ key: "beach" }}
                    position={beachMarker}
                    icon={beachMarkerIcon}
                    onClick={(e: any) => {
                        onClickMarker(e.target.options);
                    }}
                />
                <Marker
                    data={{ key: "palais" }}
                    position={palaisMarker}
                    icon={palaisMarkerIcon}
                    onClick={(e: any) => {
                        onClickMarker(e.target.options);
                    }}
                />
                <Marker
                    data={{ key: "appartAmaury" }}
                    position={appartAmauryMarker}
                    icon={appartAmauryMarkerIcon}
                    onClick={(e: any) => {
                        onClickMarker(e.target.options);
                    }}
                />
                <Marker
                    data={{ key: "appartCamille" }}
                    position={appartCamilleMarker}
                    icon={appartCamilleMarkerIcon}
                    onClick={(e: any) => {
                        onClickMarker(e.target.options);
                    }}
                />
                <Marker
                    data={{ key: "goToAirportMarker" }}
                    position={goToAirportMarker}
                    icon={airportMarkerIcon}
                    onClick={(e: any) => {
                        onClickMarker({
                            position: airportMarker,
                        });
                    }}
                />
                <Marker
                    data={{ key: "goToTrainStationMarker" }}
                    position={goToTrainStationMarker}
                    icon={trainStationMarkerIcon}
                    onClick={(e: any) => {
                        onClickMarker({
                            position: trainStationMarker,
                        });
                    }}
                />
                <Marker
                    data={{ key: "goToBeachMarker" }}
                    position={goToBeachMarker}
                    icon={beachMarkerIcon}
                    onClick={(e: any) => {
                        onClickMarker({
                            position: beachMarker,
                        });
                    }}
                />
                <Marker
                    data={{ key: "goToPalaisMarker" }}
                    position={goToPalaisMarker}
                    icon={palaisMarkerIcon}
                    onClick={(e: any) => {
                        onClickMarker({
                            position: palaisMarker,
                        });
                    }}
                />
                <Marker
                    data={{ key: "goToAppartAmauryMarker" }}
                    position={goToAppartAmauryMarker}
                    icon={appartAmauryMarkerIcon}
                    onClick={(e: any) => {
                        onClickMarker({
                            position: appartAmauryMarker,
                        });
                    }}
                />
                <Marker
                    data={{ key: "goToAppartCamilleMarker" }}
                    position={goToAppartCamilleMarker}
                    icon={appartCamilleMarkerIcon}
                    onClick={(e: any) => {
                        onClickMarker({
                            position: appartCamilleMarker,
                        });
                    }}
                />
            </Map>
        </MapLeafletStyled>
    );
};

export default MapLeaflet;

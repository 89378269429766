import React from "react";
import { Route, Router } from "react-router";
import { createBrowserHistory } from "history";

import styled from "styled-components";

import HomePage from "./HomePage";

import Navbar from "./Navbar";
import Studios from "./Studios";
import Alentours from "./Alentours";
import FAQ from "./FAQ";
import Galerie from "./Galerie";
import OuNousTrouver from "./OuNousTrouver";
import Evenements from "./Evenements";
import Blog from "./Blog";

const RoutesStyled = styled.div`
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;

    #content {
        -webkit-flex-grow: 1;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
`;

const browserHistory = createBrowserHistory();
export const renderRoutes = () => (
    <Router history={browserHistory}>
        <RoutesStyled>
            <Router history={browserHistory}>
                <div id="header">
                    <Navbar />
                </div>
                <div id="content">
                    <Route exact path="/" component={HomePage} />
                    <Route exact path="/Studios" component={Studios} />
                    <Route exact path="/Alentours" component={Alentours} />
                    <Route exact path="/FAQ" component={FAQ} />
                    <Route exact path="/Galerie" component={Galerie} />
                    <Route
                        exact
                        path="/OuNousTrouver"
                        component={OuNousTrouver}
                    />
                    {<Route exact path="/Blog" component={Blog} />}
                    {<Route exact path="/Evenements" component={Evenements} />}
                </div>
            </Router>
        </RoutesStyled>
    </Router>
);

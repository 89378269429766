import React, { useState } from "react";
import { Route } from "react-router";
import styled from "styled-components";

import logo from "./images/cannesBWSmall.jpg";
import mapBackground from "./images/mapBackground.jpg";
import blogBackground from "./images/blogBackground.jpg";
import faqBackground from "./images/faqBackground.jpg";
import alentoursBackground from "./images/alentoursBackground.jpg";
import eventsBackground from "./images/eventsBackground.jpg";
import historyBackground from "./images/historyBackground.jpg";
import studiosBackground from "./images/studiosBackground.jpg";
import { mediumWidth, bigWidth } from "./ui/Constants";
import Block from "./ui/Block";

const HomePageStyled = styled.div`
    .ImgBW {
        margin-top: 5vh;
        width: 100%;
        height: 50vh;
        background-position: center;
        background-size: cover;
    }

    .HPcontent {
        .wrapper {
            height: 200vh;
            margin: auto;
            margin-bottom: 3vh;
            display: grid;
            grid-gap: 3vh;
        }
    }

    .title-block {
        margin: auto;
        height: 10vh;
        width: 80%;
        margin-top: 5vh;
        margin-bottom: 5vh;
    }

    @media only screen and (max-width: ${mediumWidth}) {
    }

    // When width > medium-width
    @media only screen and (min-width: ${mediumWidth}) {
        .HPcontent {
            .one {
                grid-column-start: 1;
                grid-column-end: 6;
                grid-row: 1;
            }

            .two {
                grid-column-start: 6;
                grid-column-end: 10;
                grid-row: 1;
            }

            .three {
                grid-column-start: 1;
                grid-column-end: 4;
                grid-row: 2;
            }

            .four {
                grid-column-start: 4;
                grid-column-end: 10;
                grid-row: 2;
            }

            .five {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row: 3;
            }

            .six {
                grid-column-start: 3;
                grid-column-end: 10;
                grid-row: 3;
            }

            .seven {
                grid-column-start: 1;
                grid-column-end: 10;
                grid-row: 4;
            }
        }
    }

    @media only screen and (min-width: ${mediumWidth}) and (max-width: ${bigWidth}) {
    }

    @media only screen and (min-width: ${bigWidth}) {
    }
`;

const HomePage = () => {
    const [destination, setDestination] = useState("");

    if (destination) {
        return (
            <Route
                render={({ history }) => {
                    setTimeout(() => history.push(`/${destination}`), 0);
                    return null;
                }}
            />
        );
    }

    return (
        <HomePageStyled className="HomePage">
            <div
                className="ImgBW"
                style={{
                    backgroundImage: `url(${logo})`,
                    display: "flex",
                }}
            >
                <div
                    style={{
                        height: "40%",
                        margin: "auto",
                    }}
                />
            </div>
            <div className="HPcontent">
                <Block className="title-block">
                    <span>Phrase d'accroche</span>
                </Block>
                <div className="wrapper">
                    <Block
                        className="one"
                        backgroundImage={studiosBackground}
                        onClick={() => {
                            setDestination("Studios");
                        }}
                    >
                        <span>Studios</span>
                    </Block>
                    <Block
                        className="two"
                        backgroundImage={alentoursBackground}
                        onClick={() => {
                            setDestination("Alentours");
                        }}
                    >
                        <span>Alentours</span>
                    </Block>
                    <Block
                        className="three"
                        backgroundImage={faqBackground}
                        onClick={() => {
                            setDestination("FAQ");
                        }}
                    >
                        <span>FAQ</span>
                    </Block>
                    <Block
                        className="four"
                        backgroundImage={historyBackground}
                        onClick={() => {
                            setDestination("Galerie");
                        }}
                    >
                        <span>Galerie</span>
                    </Block>
                    <Block
                        className="five"
                        backgroundImage={mapBackground}
                        onClick={() => {
                            setDestination("OuNousTrouver");
                        }}
                    >
                        <span>Où nous trouver</span>
                    </Block>
                    <Block
                        className="six"
                        backgroundImage={blogBackground}
                        onClick={() => {
                            setDestination("Blog");
                        }}
                    >
                        <span>Blog</span>
                    </Block>
                    <Block
                        className="seven"
                        backgroundImage={eventsBackground}
                        onClick={() => {
                            setDestination("Evenements");
                        }}
                    >
                        <span>Evenements</span>
                    </Block>
                </div>
            </div>
        </HomePageStyled>
    );
};

export default HomePage;

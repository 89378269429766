import React from "react";
import styled from "styled-components";
import { mediumWidth } from "./ui/Constants";
import { useState } from "react";
import Block from "./ui/Block";

import img01 from "./images/Studio2/01.png";
import img02 from "./images/Studio2/02.png";
import img03 from "./images/Studio2/03.png";
import img04 from "./images/Studio2/04.png";
import img05 from "./images/Studio2/05.png";
import img06 from "./images/Studio2/06.png";
import img07 from "./images/Studio2/07.png";
import img08 from "./images/Studio2/08.png";
import img09 from "./images/Studio2/09.png";
import img10 from "./images/Studio2/10.png";
import img11 from "./images/Studio2/11.png";
import img12 from "./images/Studio2/12.png";
import img13 from "./images/Studio2/13.png";
import img14 from "./images/Studio2/14.png";
import img15 from "./images/Studio2/15.png";
import img16 from "./images/Studio2/16.png";
import img17 from "./images/Studio2/17.png";
import img18 from "./images/Studio2/18.png";

const GalerieStyled = styled.div`
    .full-screen {
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1050;

        .background {
            width: 100vw;
            height: 100vh;
            background: black;
            opacity: 0.6;
            top: 0;
            position: absolute;
            z-index: 1100;
        }

        .one-galerie {
            width: 100vw;
            height: 100vh;
            z-index: 1200;
            display: flex;
            position: absolute;
            top: 0;

            .container {
                z-index: 1200;
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                opacity: 1;
                height: 80%;
                margin-top: auto;
                margin-bottom: auto;
            }

            .image-container {
                flex-direction: row;
                display: flex;
                flex-grow: 1;

                .left {
                    width: 3vmin;
                    margin: auto;
                    color: white;
                    font-size: 5vh;
                }

                .image {
                    margin: auto;
                    flex-grow: 1;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    height: 80vh;
                }

                .right {
                    width: 3vmin;
                    margin: auto;
                    color: white;
                    font-size: 5vh;
                }
            }
        }
    }

    .galerie {
        .galerie-content {
            .wrapper {
                height: 200vh;
                margin: auto;
                margin-bottom: 3vh;
                display: grid;
                grid-gap: 1vh;
            }
        }
    }

    @media only screen and (max-width: ${mediumWidth}) {
        galerie {
            .galerie-content {
                .wrapper {
                    height: 100vh;
                }
            }
        }
    }
`;

const imgs = [
    img01,
    img02,
    img03,
    img04,
    img05,
    img06,
    img07,
    img08,
    img09,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
];

const Galerie = () => {
    const [fullScreen, setFullScreen] = useState(false);
    const [currentImg, setCurrentImg] = useState("");

    const switchFullScreen = () => {
        if (fullScreen) {
            enableSrolling();
        } else {
            disableSrolling();
        }
        setFullScreen(!fullScreen);
    };

    const disableSrolling = () => {
        const body = document.querySelector("body");

        if (body && body.style) {
            body.style.overflow = "hidden";
        }
    };

    const enableSrolling = () => {
        const body = document.querySelector("body");

        if (body && body.style) {
            body.style.overflow = "auto";
        }
    };

    const displayOneLine = (imgs: Array<string>) => {
        return (
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: `repeat(${imgs.length}, 1fr)`,
                    gridGap: "1vh",
                }}
            >
                {imgs.map((urlImg: string) => (
                    <div
                        style={{
                            backgroundImage: `url(${urlImg})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                        onClick={() => {
                            setCurrentImg(urlImg);
                            switchFullScreen();
                        }}
                    />
                ))}
            </div>
        );
    };

    const displayFullScreen = () => (
        <div
            className="full-screen"
            style={{
                top: window.pageYOffset,
            }}
        >
            <div className="background" />
            <div
                className="one-galerie"
                onClick={() => {
                    setCurrentImg("");
                    switchFullScreen();
                }}
            >
                <div className="container">
                    <div className="image-container">
                        <div className="left">{"<"}</div>
                        <div
                            className="image"
                            style={{
                                backgroundImage: `url(${currentImg})`,
                            }}
                        ></div>
                        <div className="right">{">"}</div>
                    </div>
                </div>
            </div>
        </div>
    );
    const galerie = () => {
        return (
            <div className="galerie">
                <div className="galerie-content">
                    <Block>
                        <span>Galerie</span>
                    </Block>
                    <div className="wrapper">
                        {displayOneLine([imgs[0], imgs[1], imgs[2], imgs[3]])}
                        {displayOneLine([imgs[4], imgs[5], imgs[6]])}
                        {displayOneLine([imgs[7], imgs[9]])}
                        {displayOneLine([imgs[8]])}
                        {displayOneLine([imgs[10], imgs[11], imgs[12]])}
                        {displayOneLine([
                            imgs[13],
                            imgs[14],
                            imgs[15],
                            imgs[17],
                        ])}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <GalerieStyled>
            {fullScreen && displayFullScreen()}
            {galerie()}
        </GalerieStyled>
    );
};

export default Galerie;
